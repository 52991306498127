import React from 'react'
import '../assets/fonts/fonts.css'
import { connect } from 'react-redux'
import { ThemeProvider, Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme, reset } from '../styles'
import Footer from './Footer'

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.text};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.text};
    background-color: ${theme.colors.background};
  }
  ::selection {
    color: ${theme.colors.text};
    background-color: ${theme.colors.selection};
  }
  a {
    color: ${theme.colors.link};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`
const IndexImage = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: auto;
    padding-top: 8rem;
  }
`
const MainWrapper = styled.div`
  min-height: calc(100vh - 150px);
`

class Layout extends React.Component {

  render() {
    const { children } = this.props;
    
    return (
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle} />
        {
          this.props.location.pathname === '/' ? (
            <React.Fragment>
              <IndexImage>
                {children}
              </IndexImage>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MainWrapper style={{
                display: this.props.modalBanner ? 'none' : 'block',
                transition: 'opacity 200ms ease-in'
              }}>
                <div>{children}</div>
              </MainWrapper>
              <Footer style={{
                display: this.props.modalBanner ? 'none' : 'block',
                transition: 'opacity 200ms ease-in'
              }}>
                <div>
                  <span>
                    Suivre Rémy Disch sur
                    {` `}
                    <a 
                      href="https://www.instagram.com/remydisch/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    {` `}et{` `}
                    <a 
                      href="https://www.facebook.com/remy.disch/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </span>
                </div>
                <div>© 2021 Rémy Disch - Tous droits réservés - les Z 2021</div>
              </Footer>
            </React.Fragment>
          )
        }
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});

export default connect(mapStateToProps, null)(Layout);