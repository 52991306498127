import styled from '@emotion/styled'

export const FooterContainer = styled.footer`
  font-family: ${props => props.theme.fontFamily};
  width: 100%;
  margin: 2rem auto 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.grey};
  text-align: center;
  text-Transform: uppercase;
  font-size: 13px;
  > div:last-of-type {
    letter-spacing: .3px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 13px;
    > div:last-of-type {
      letter-spacing: .3px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 9px;
    > div:last-of-type {
      letter-spacing: .2px;
    }
  }
  a {
    color: ${props => props.theme.colors.black};
    font-style: normal
  }

  span {
    font-size: 15.2px;
    display: block;
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      font-size: 11px;
    }
  }
`