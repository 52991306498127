const theme = {
  colors: {
    background: '#f2f2f2',
    // text: '#050505',
    text: '#595C62',
    link: '#050505',
    selection: '#050505',
    grey: '#9d9d9d',
    hardGrey: '#797979',
    black: '#494949',
  },
  fonts: {
    header: {
      // medium: 'Helvetica-Medium',
      medium: "'Raleway-Medium', sans-serif",
      subtitle: "'DIN-Medium', sans-serif",
      size: '2rem'
    },
    menu: {
      // medium: 'Helvetica-Medium',
      medium: "'DIN-Medium', sans-serif",
      size: '1.2rem'
    },
    titles: {
      light: "'Raleway-light', sans-serif",
      regular: "'Raleway-Regular', sans-serif",
      medium: "'Raleway-Medium', sans-serif",
      bold: "'Raleway-Medium', sans-serif",
      size: '1.05rem',
    },
    subTitles: {
      light: "'DIN-Light', sans-serif",
      regular: "'DIN-Regular', sans-serif",
      medium: "'DIN-Medium', sans-serif",
      bold: "'DIN-Bold', sans-serif",
      size: '.9rem',
    },
    credits: {
      light: "'Raleway-light', sans-serif",
      regular: "'Raleway-Regular', sans-serif",
      medium: "'Raleway-Medium', sans-serif",
      bold: "'Raleway-Medium', sans-serif",
      size: '.8rem',
    },
    p: {
      light: "'DIN-Light', sans-serif",
      regular: "'DIN-Regular', sans-serif",
      // medium: "'DIN-Medium', sans-serif",
      medium: "'Raleway-Medium', sans-serif",
      bold: "'DIN-Bold', sans-serif",
      size: '.9rem',
    },
    a: {
      light: "'Raleway-light', sans-serif",
      regular: "'Raleway-Regular', sans-serif",
      medium: "'Raleway-Medium', sans-serif",
      bold: "'Raleway-Medium', sans-serif",
      size: '.9rem',
    },
    default: "'Raleway-Regular', sans-serif",
  },
  fontFamily: "'Source Sans Pro','Roboto','Helvetica', 'Arial', sans-serif",
  maxWidth: '1072px',
  breakpoints: {
    xxs: '320px',
    xs: '420px',
    xsLandscape: '668px',
    s: '640px',
    m: '823px',
    l: '1024px',
  },
}

export default theme
